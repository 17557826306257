<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        Modificar serie
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4 pb-0 text-center body-1">
        Cambiar la serie <strong>{{ serie }}</strong> del artículo <strong>{{ articulo_nombre }}</strong> por
        <v-text-field
          v-model.trim="nueva"
          class="mt-2"
          autofocus
          outlined
          dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="error"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          titulo="Confirmar cambio"
          :disabled="!serie || !nueva || serie.toLowerCase() == nueva.toLowerCase()"
          :loading="load"
          :texto="`¿Desea modificar la serie <strong>${serie}</strong> por <strong>${nueva.toUpperCase()}</strong>?`"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 *  Modal para modificar una serie
 * 
 *  Notas: Se debe enviar la serie vieja en el prop serie.
 *         Este componente emite la accion (@actualizar) al padre caundo se modifica la serie
 */
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      nueva: ''
    }
  },
  props: {
    value: Boolean,
    serie: {
      type: String,
      default: ''
    },
    articulo_nombre: {
      type: String,
      default: ''
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async dialog () {
      this.nueva = ''
    }
  },
  methods: {
    async guardar () {
      if (this.nueva == '') {
        return this.$store.dispatch('show_snackbar', {
          text: 'Ingrese una serie nueva',
          color: 'orange'
        })
      }
      if (this.nueva.toLowerCase() == this.serie.toLowerCase()) {
        return this.$store.dispatch('show_snackbar', {
          text: 'La serie nueva no puede ser igual a la anterior',
          color: 'orange'
        })
      }
      this.load = true
      await this.$store.dispatch('genericosAcc/modificar_serie', {
        vieja: this.serie,
        nueva: this.nueva
      })
        .then((res) => {
          this.dialog = false
          // emite la accion definifa en el padre
          this.$emit('actualizar', this.nueva)
          // muestra mensaje
          this.$swal.fire({
            title: res.message,
            icon: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load = false
    }
  }
}
</script>